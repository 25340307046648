import * as angular from 'angular';
import { IState } from 'angular-ui-router';
import { TransitionService } from '@uirouter/core';
import { DataEntityService } from '@services/data-entity.service';
import { LanguageService } from '@services/language.service';
import { NotifyEvents, NotifyingService } from '@services/notifying.service';
import { PermissionService } from '@services/permission.service';
import { PostSaveActions } from 'src/app/base.controller';
import { MainDialogController } from './pages/main-dialog.controller';

export class MainController {
  private _mdDialog: angular.material.IDialogService;
  private _state: angular.ui.IStateService;
  private _transitions: TransitionService;
  private _dataEntityService: DataEntityService;
  private _languageService: LanguageService;
  private _notifyingService: NotifyingService;
  private _permissionService: PermissionService;

  constructor(
    $mdDialog: angular.material.IDialogService,
    $state: angular.ui.IStateService,
    $transitions: TransitionService,
    DataEntityService: DataEntityService,
    LanguageService: LanguageService,
    NotifyingService: NotifyingService,
    PermissionService: PermissionService,
  ) {
    this._state = $state;
    this._transitions = $transitions;
    this._mdDialog = $mdDialog;
    this._dataEntityService = DataEntityService;
    this._languageService = LanguageService;
    this._notifyingService = NotifyingService;
    this._permissionService = PermissionService;

    document.addEventListener('fullscreenchange', this.fullScreenModeChanged.bind(this));
  }

  $onInit() {
    this._transitions.onBefore({}, (trans) => {
      if (!this._dataEntityService.hasFormOrDataChanges) {
        return;
      }

      const currentAccount = this._permissionService.currentAccount;
      const transitionTo = trans.to();
      const transitionFrom = trans.from();
      const transitionParams = trans.params();

      if (!currentAccount) {
        this._languageService.warning('PROJ.NO_ACCOUNT');
        this._dataEntityService.rejectChanges();

        return trans.router.stateService.target(transitionTo);
      }

      const canSave = !['Viewer', 'Operator'].includes(currentAccount.role);

      if (!canSave) {
        this._dataEntityService.rejectChanges();
        this._dataEntityService.hasDirtyCustomForm = false;

        if (transitionTo.name === transitionFrom.name) {
          this._state.go(transitionTo.name, transitionParams, { reload: true });
        } else {
          this._state.go(transitionTo.name, transitionParams);
        }

        return;
      }

      const changeToAccountId = trans.targetState().params()?.isAttemptToChangeToAccountId;
      const changeAccount = (accountId: number) => {
        const targetAccount = this._permissionService.accounts.find((a) => a.accountId === accountId);

        return this._permissionService.changeAccount(targetAccount);
      }

      this._mdDialog.show({
        controller: MainDialogController,
        controllerAs: 'vm',
        templateUrl: 'src/app/pages/main-dialog.html',
        parent: null,
        clickOutsideToClose: false,
        fullscreen: true,
        locals: {
          currentUrl: this._state.current.name,
        },
      })
      .then((response) => {
        if (!response) {
          return;
        }

        const shouldDiscard = response.dialogValue === 'discard';

        if (shouldDiscard) {
          this._dataEntityService.rejectChanges();
          this._dataEntityService.hasDirtyCustomForm = false;

          if (!changeToAccountId) {
            this._state.go(transitionTo as IState, transitionParams);
          } else {
            changeAccount(changeToAccountId);
          }

          return;
        }

        const shouldSave = response.dialogValue === 'save';

        if (shouldSave) {
          const saveEventTypeMapper = {
            // NOTE: Please maintain keys alphabetical order.
            'app.account.assetgroups.detail': NotifyEvents.App.SaveChanges.AssetGroup,
            'app.account.equipments.detail': NotifyEvents.App.SaveChanges.Equipment,
            'app.account.groups.detail': NotifyEvents.App.SaveChanges.Group,
            'app.account.infrastructures.detail': NotifyEvents.App.SaveChanges.Infrastructure,
            'app.account.sites.detail': NotifyEvents.App.SaveChanges.Site,
            'app.profile': NotifyEvents.App.SaveChanges.Profile,
            'app.water.allocations.detail': NotifyEvents.App.SaveChanges.WaterAllocation,
            'app.water.budgets.detail': NotifyEvents.App.SaveChanges.WaterBudget,
            'app.water.irrigation-override': NotifyEvents.App.SaveChanges.WaterIrrigationOverride,
            'app.water.irrigation-plans.detail': NotifyEvents.App.SaveChanges.WaterIrrigationPlan,
          }

          const saveEventType = saveEventTypeMapper[transitionFrom.name];

          if (saveEventType) {
            const shouldNavigateToRoute = !changeToAccountId;
            const data = {
              navigateTo: shouldNavigateToRoute ? () => this._state.go(transitionTo.name, transitionParams) : undefined,
              changeAccount: () => !shouldNavigateToRoute ? changeAccount(changeToAccountId) : undefined,
            } as PostSaveActions;

            this._notifyingService.notify(saveEventType, data);
          } else {
            this._dataEntityService.saveChanges().then(
              () => {
                this._dataEntityService.hasDirtyCustomForm = false;
                this._languageService.showSaveSuccess();
                this._state.go(transitionTo.name, transitionParams);
              },
              (error) => {
                this._languageService.showSaveFailure(error.message);
              },
            );
          }
        }
      });

      // Stop the route transition.
      return false;
    });
  }

  private fullScreenModeChanged(): void {
    // Check if the page contains '-google-map' div container first, if does not then ignore.
    const googleMapDiv = document.querySelector('[id*="-google-map"] div');

    if (!googleMapDiv) {
      return;
    }

    const mdDatePickerFullScreenCiontainerId = 'md-date-picker-fullscreen';
    const toastTargetFullScreenContainerId = 'toast-target-fullscreen';
    const isFullScreenMode = document['fullscreenElement'];

    this._notifyingService.notify(NotifyEvents.Map.FullScreenModeChanged, {
      isFullScreenMode: isFullScreenMode,
    });

    if (isFullScreenMode) {
      const createDiv = (id: string): HTMLElement => {
        const div = document.createElement('div');

        div.id = id;

        return div;
      };

      // Fullscreen mode enabled, thus create and inject required 'div' container to maintain required functionality in full screen mode.
      const mdDatePickerFullScreenDiv = createDiv(mdDatePickerFullScreenCiontainerId);
      const toastTargetFullScreenDiv = createDiv(toastTargetFullScreenContainerId);

      googleMapDiv.append(mdDatePickerFullScreenDiv);
      googleMapDiv.append(toastTargetFullScreenDiv);

      this._languageService.clearToaster();
    } else {
      const removeDiv = (id: string): void => {
        const element = document.getElementById(id);

        element?.remove();
      };

      removeDiv(mdDatePickerFullScreenCiontainerId);
      removeDiv(toastTargetFullScreenContainerId);
    }
  }
}

angular.module('fuse').controller('MainController', MainController);
