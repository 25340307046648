import * as angular from 'angular';
import * as auth0 from 'auth0-js';
import { AppSettings } from '@indicina/swan-shared/AppSettings';
import { i18nexus } from '@indicina/swan-shared/configuration/i18nexus';
import { ClientConfig } from '@indicina/swan-shared/interfaces/config/Settings';
import { SWANConstants } from '@common/SWANConstants';
import { CommonHelper } from '@common/helpers/CommonHelper';

const bootstrapAngular = () => {
  // NOTE: An attempt was made to use 'document.addEventListener('DOMContentLoaded', async () => {...}' instead
  // of deprecated 'angular.element(document).ready(async () => {...}', however it seems it does not always trigger,
  // hence the Angular app might not get bootstrapped (i.e., remains stuck on an empty/white page).
  angular.element(document).ready(async () => {
    // Set 'apiBaseUrl' required by 'CommonHelper.getApiUrl' function.
    SWANConstants.apiBaseUrl = AppSettings.app.apiBaseUrl;

    await i18nexus.initialise(AppSettings.app.envCode, AppSettings.i18nexus.targetVersion);

    angular.bootstrap(document, ['fuse']); // angular.bootstrap(document, ['fuse'], { strictDi: true });
  });
}

fetch('assets/config/client-config.json', { cache: 'no-store' })
  .then((response) => {
    return response.json();
  })
  .then((configData: ClientConfig) => {
    AppSettings.initialise(configData);

    if (localStorage.getItem('token') == null) {
      const auth = new auth0.WebAuth({
        audience: AppSettings.auth0.audience,
        clientID: AppSettings.auth0.clientId,
        cookieDomain: AppSettings.auth0.cookieDomain,
        domain: AppSettings.auth0.domain,
        redirectUri: AppSettings.app.clientUrl,
        responseType: 'token',
        scope: 'openid profile email',
      });

      if (CommonHelper.isRedirectFromAuthentication()) {
        auth.parseHash((err, authResult: auth0.Auth0Result) => {
          if (authResult?.accessToken) {
            localStorage.setItem('token', authResult.accessToken);

            bootstrapAngular();

            return;
          }

          localStorage.removeItem('token');

          auth.logout({
            returnTo: AppSettings.auth0.logoutUrl,
          });
        });
      } else {
        // Redirect to auth0 login instead of bootstrapping angular
        auth.authorize();
      }
    } else {
      bootstrapAngular();
    }
  });
