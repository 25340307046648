import * as angular from 'angular';
import { Transition, TransitionService } from '@uirouter/angularjs';
import { CommonHelper } from '@common/helpers/CommonHelper';
import { AuthZeroService } from './_services/auth-zero.service';
import { InitialisationService } from './_services/initialisation.service';
import { DataEntityService } from './_services/data-entity.service';
import { LocalStorageService } from './_services/local-storage.service';
import { PermissionService } from './_services/permission.service';
import { AppSettings } from '@indicina/swan-shared/AppSettings';

angular.module('fuse').run([
  '$http',
  '$rootScope',
  '$state',
  '$transitions',
  'AuthZeroService',
  'DataEntityService',
  'InitialisationService',
  'LocalStorageService',
  async (
    $http: angular.IHttpService,
    $rootScope: angular.IRootScopeService,
    $state: angular.ui.IStateService,
    $transitions: TransitionService,
    AuthZeroService: AuthZeroService,
    DataEntityService: DataEntityService,
    InitialisationService: InitialisationService,
    LocalStorageService: LocalStorageService,
  ) => {
    let pendingTransition: Transition;

    const initialise = async (): Promise<void> => {
      const getVersion = async (): Promise<boolean> => {
        try {
          const response = (await $http.get(CommonHelper.getApiUrl('auth/getVersion')))?.data as fuse.versionDto

          LocalStorageService.dataEntryDays = response.dataEntryDays;

          return true;
        } catch (err) {
          console.error(err);

          $state.go('app.auth.login', { isApiError: true });
        }
      };

      const hasValidResponse = await getVersion();

      if (!hasValidResponse) {
        console.error('There was an issue encountered with data retrieval from the \'getVersion\' API endpoint.');

        return;
      }

      const isBrowserSupported = (): boolean => {
        const userAgent = navigator.userAgent.toLowerCase();
        let isSupported = true;

        const flagAsUnsupportedIf = (keyword: string, shouldInclude: boolean): void => {
          if (userAgent.includes(keyword) !== shouldInclude) {
            isSupported = false;
          }
        };

        for (const browser of AppSettings.supportedBrowsers) {
          isSupported = true;

          for (const keyword of browser.keywords) {
            const searchKeyword = keyword.trim().toLowerCase();

            if (!searchKeyword.length) {
              continue;
            }

            const isExpectedKeyword = !searchKeyword.startsWith('!');

            // Flag as unsupported based on the expected/unexpected keyword searches within the 'userAgent' string.
            flagAsUnsupportedIf(isExpectedKeyword ? searchKeyword : searchKeyword.substring(1), isExpectedKeyword);
          }

          if (isSupported) {
            // If a browser support passed, then ignore the remaining browsers and exit early.
            break;
          }
        }

        return isSupported;
      };

      await InitialisationService.init(isBrowserSupported());

      if (!DataEntityService.hasMetadata) {
        // NOTE: Loading Breeze's 'MetaData' here as accessing some pages directly (i.e., specific URL request or page refresh)
        // requires the data before the requested page can be successfully processed.
        await DataEntityService.loadMetadata();
      }

      if (pendingTransition) {
        // Handle previously prevented route transition (e.g., due to incomplete initialisation).
        $state.go(pendingTransition.$to().name, pendingTransition.params());
      }

      $rootScope.$emit('whatAccount');
    };

    const shouldPreventTransitionToRoute = (isInitialState: boolean, transition: Transition): boolean => {
      const isTransitionToLogin = () => {
        const transitionTo = transition.$to();

        return transitionTo.name === 'app.auth.login';
      };

      const isLoginRouteRefresh = isInitialState && CommonHelper.isRoutePath('login');

      // Prevent manual page refresh with '/login' route.
      if (isLoginRouteRefresh) {
        AuthZeroService.logout();

        return true;
      }

      if (!isInitialState) {
        return false;
      }

      if (isTransitionToLogin()) {
        // Transitions to the 'login' state are allowed.
        return false;
      }

      if (!InitialisationService.isInitialised) {
        // Prevent route transition as incomplete initialisation, but remember the transition reference for later use.
        pendingTransition = transition;

        return true;
      }

      return false;
    };

    // Registration of route transitions 'onStart' event callback.
    const transitionsStartEvent = $transitions.onStart({ to: true }, (transition) => {
      const isInitialState = !transition.$from()?.name;
      const transitionTo = transition.$to();

      if (shouldPreventTransitionToRoute(isInitialState, transition)) {
        // Prevent continuation of the route transition.
        return false;
      }

      if (!transitionTo.data) {
        return;
      }

      const permissions = transitionTo.data.permission ? [transitionTo.data.permission] : transitionTo.data.permissions;

      if (permissions) {
        const permissionService = (transition.injector().get('PermissionService')) as PermissionService;
        const hasPermissions = permissionService.hasPermission(permissions);

        if (!hasPermissions) {
          AuthZeroService.logout();
        }
      }
    });

    // Registration of route transitions 'onSuccess' event callback.
    const transitionsSuccessEvent = $transitions.onSuccess({ to: true }, (transition) => {
      const isInitialState = !transition.$from()?.name;
      const transitionTo = transition.$to();
      const permissionService = (transition.injector().get('PermissionService')) as PermissionService;

      const getNavigatationTargetState = () => {
        const navigationTargetState = transitionTo.name;

        if (navigationTargetState.startsWith('app.account.assetgroups')) {
          return navigationTargetState.replace('app.account.assetgroups', 'app.account.groups');
        }

        return navigationTargetState;
      }

      // NOTE: Timeout is required to ensure the menu exist after inital app start.
      setTimeout(() => {
        CommonHelper.setNavActiveItemForState(getNavigatationTargetState(), permissionService.accountCustomStyling?.swanActiveNavigation);
      }, isInitialState ? 2000 : 0);
    });

    // Cleanup
    $rootScope.$on('$destroy', () => {
      transitionsStartEvent?.();
      transitionsSuccessEvent?.();
    });

    await initialise();
  },
]);
