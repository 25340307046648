import * as angular from 'angular';
import { MainController } from './main.controller';
import { NavigationController } from './navigation/navigation.controller';
import { ToolbarController } from './toolbar/toolbar.controller';
import { QuickPanelController } from './quick-panel/quick-panel.controller';

angular.module('fuse').config([
  '$stateProvider',
  ($stateProvider: angular.ui.IStateProvider) => {
    $stateProvider.state('app', {
      abstract: true,
      views: {
        'main@': {
          templateUrl: 'src/app/main.html',
          controller: MainController,
          controllerAs: 'vm',
        },
        'toolbar@app': {
          templateUrl: 'src/app/toolbar/toolbar.html',
          controller: ToolbarController,
          controllerAs: 'vm',
        },
        'navigation@app': {
          templateUrl: 'src/app/navigation/navigation.html',
          controller: NavigationController,
          controllerAs: 'vm',
        },
        'quickPanel@app': {
          templateUrl: 'src/app/quick-panel/quick-panel.html',
          controller: QuickPanelController,
          controllerAs: 'vm',
        },
      },
    });
  },
]);
