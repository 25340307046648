import * as angular from 'angular';
import { AppSettings } from '@indicina/swan-shared/AppSettings';
import { AppUtils } from '@indicina/swan-shared/utils/AppUtils';
import { LocalStorageUtils } from '@indicina/swan-shared/utils/LocalStorageUtils';
import { InitialisationService } from '@services/initialisation.service';
import { AccountModel, PermissionService } from '@services/permission.service';

class IndexController {
  public themes: any;
  public accounts: AccountModel[];
  public searchText = '';

  private _rootScope: angular.IRootScopeService;
  private _permissionService: PermissionService;

  constructor(
    fuseTheming: any,
    $rootScope: angular.IRootScopeService,
    InitialisationService: InitialisationService,
    PermissionService: PermissionService,
  ) {
    this.themes = fuseTheming.themes;

    this._rootScope = $rootScope;
    this._permissionService = PermissionService;

    this._rootScope.$on('whatAccount', async (_event) => {
      if (this.currentAccount) {
        await PermissionService.loadAccountLogo();
      }

      this._rootScope.$broadcast('accountChanged');
    });

    this._rootScope.$on('accountLogoUpdated', async (_event) => {
      await PermissionService.loadAccountLogo(true); // Refresh logo in the local storage.
    });

    PermissionService.onNewAccounts = (accounts: AccountModel[]) => {
      this.accounts = accounts;

      if (!this.accounts?.length) {
        return;
      }

      const getTargetAccount = (accountId: number) => this.accounts.find((a) => a.accountId === accountId);

      // Update 'userInitData' reference stored in the service instance with the latest 'accounts' data.
      InitialisationService.updateUserInitData(x => x.permissions = accounts);

      const context = LocalStorageUtils.contextData;
      const accountId = context.accountId ?? this.accounts[0].accountId;
      const targetAccount = getTargetAccount(accountId) ?? this.accounts[0];

      // Do not change account if already set (this callback also fires on refresh token).
      if (!this.currentAccount || this.currentAccount.accountId === accountId) {
        PermissionService.setCurrentAccount(targetAccount);

        return;
      }

      PermissionService.changeAccount(targetAccount);
    };
  }

  public get currentAccount(): AccountModel {
    return this._permissionService.currentAccount;
  }

  $onInit() {
    AppUtils.updateAppTitle(AppSettings.app.envCode);
  }
}

angular.module('fuse').controller('IndexController', IndexController);
